:root {
    --bezier: cubic-bezier(0.75, 0, 0.25, 1);
}

* {
    margin: 0;
    box-sizing: border-box;
    font-family: HelveticaNeue, Helvetica, sans-serif;
}



.nav__trigger-finger {
    position: fixed;
    color: hotpink;
}

.nav__trigger-finger::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    background: hotpink;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.15s var(--bezier);
}

.nav__trigger-finger span {
    display: block;
    width: 100%;
    height: 2px;
    transition: transform 1s var(--bezier);
    background: currentColor;
}

.nav__trigger-finger span::before,
.nav__trigger-finger span::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    height: 2px;
    background: currentColor;
    transition: transform 0.2s var(--bezier), top 0.2s 0.2s var(--bezier), background 0.1s var(--bezier);
}

.nav__trigger-finger span::before {
    top: 0;
}

.nav__trigger-finger span::after {
    top: calc(100% - 2px);
}

.nav__trigger-input,
.nav__submenu-trigger-input {
    opacity: 0;
    width: 0;
    height: 0;
    appearance: none;
    position: fixed;
}

.nav__trigger-input:focus + .nav__trigger-finger {
    color: white;
}

.nav__trigger-input:focus + .nav__trigger-finger::before {
    opacity: 1;
}

.nav__trigger-input:checked + .nav__trigger-finger {
    /*height: 14px;*/
    color: white;
}

.nav__trigger-input:checked + .nav__trigger-finger span {
    transform: rotate(45deg);
}

.nav__trigger-input:checked + .nav__trigger-finger span::before,
.nav__trigger-input:checked + .nav__trigger-finger span::after {
    top: calc(50% - 1px);
    transition: transform 0.2s 0.2s var(--bezier), top 0.2s var(--bezier), background 0.1s var(--bezier);
}

.nav__trigger-input:checked + .nav__trigger-finger span::after {
    transform: rotate(90deg);
}

.nav__trigger-input:checked ~ .nav__list {
    z-index: 9;
    transform: none;
    transition: 0.25s var(--bezier);
}
.nav__list {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
    background: hotpink;
    font-size: 3rem;
    padding: 40px;
    transform: translate(190vw) rotate(49deg);
    transition: 0.1s var(--bezier);
}

@media screen and (max-width: 1200px) {
    .nav__list {
        transform: translate(170vw) rotate(45deg);
    }
}
@media screen and (min-width: 1201px) {
    .nav__list {
        transform: translate(100vw) rotate(45deg);
    }
}


.nav__item {
    list-style: none;
}

.nav__item + .nav__item {
    margin-top: 20px;
    z-index: 99;
}

.nav__submenu-trigger-input:checked + .nav__submenu-trigger-finger::after {
    transform: translateY(10px);
}

.nav__submenu-trigger-input:checked ~ .nav__list-child {
    display: block;
}

.nav__submenu-trigger-input:focus + .nav__submenu-trigger-finger::after {
    transform: translateY(5px);
}

.nav__submenu-trigger-finger::after {
    content: '';
    display: inline-block;
    border: 10px solid transparent;
    border-top-color: white;
    transition: 0.2s var(--bezier);
}

.nav__link {
    text-decoration: none;
    color: inherit;
}

.nav__list-child {
    display: none;
    font-size: 2rem;
    margin-top: 10px;
    padding-left: 2rem;
}

main {
    padding: 6rem 4rem;
}

main h1 {
    font-size: 3rem;
    font-weight: normal;
}

main h1 span {
    color: hotpink;
}

main p {
    line-height: 1.5;
    margin-top: 1.5rem;
}
